import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeValue?: string;
	badgeClass?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	Menusub?: boolean;
	target?: boolean
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {
	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next;
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [
		{
			headTitle: 'Principal',
		},
		{
			title: 'Dashboard', icon: 'home', type: 'sub', Menusub: true, active: false, children: [
				{ path: '/dashboard/vendas', title: 'Vendas', type: 'link' },
				{ path: '/dashboard/sales-reports', title: 'Relatório de vendas', type: 'link' }
			]
		},
		{
			headTitle: 'Gestão',
		},
		{
			title: 'Administração', icon: 'shield', type: 'sub', Menusub: true, active: false, children: [
				{ path: '/dashboard/users', title: 'Usuários', type: 'link' },
				{ path: '/dashboard/sellers', title: 'Vendedor / Influencer', type: 'link' },
				{ path: '/dashboard/costs', title: 'Taxas Cadastradas', type: 'link' },
			]
		},
		{
			title: 'Vendas', icon: 'shopping-cart', type: 'sub', Menusub: true, active: false, children: [
				/* { path: '/dashboard/phases', title: 'Etapas', type: 'link' }, */

				{ path: '/dashboard/clients', title: 'Clientes', type: 'link' },
				{ path: '/dashboard/campaigns-sales', title: 'Campanhas', type: 'link' },
				/* { path: '/dashboard/sales', title: 'Vendas', type: 'link' }, */
			]
		},


		{
			title: 'Venda Comissão', icon: 'file', type: 'sub', Menusub: true, active: false, children: [
				// {
				// 	title: 'Distribuidores', icon: 'shopping-bag', type: 'sub', Menusub: true, active: false, children: [
				// 		{ path: '/vendas/distribuidores', title: 'Vendas distribuidores', type: 'link' },

				// 	]
				// },

				{
					title: 'Vendedores', icon: 'shopping-bag', type: 'sub', Menusub: true, active: false, children: [
						{ path: '/dashboard/sales', title: 'Comissão Vendedores', type: 'link' },
					]
				},
			]
		},
		{
			title: 'Campanha', icon: 'shopping-cart', type: 'sub', Menusub: true, active: false, children: [
				{ path: '/dashboard/campaigns', title: 'Campanhas', type: 'link' },
				{ path: '/dashboard/scratch-cards', title: 'Raspadinhas', type: 'link' },
				{ path: '/dashboard/images', title: 'Imagens', type: 'link' },
				{ path: '/dashboard/buyer-ranking', title: 'Ranking', type: 'link' },
				/* { path: '/dashboard/generate-pix', title: 'Gerar Pix', type: 'link' },*/
				{ path: '/dashboard/prize-time', title: 'Hora Premiada', type: 'link' },
				{ path: '/dashboard/smaller-number', title: 'Menor Número Premiado', type: 'link' },
				{ path: '/dashboard/ranking-hour', title: 'Ranking Premiado', type: 'link' },
				/*{ path: '/dashboard/block-number', title: 'Bloquear Número', type: 'link' },*/
			]
		},

		{
			title: 'Financeiro', icon: 'credit-card', type: 'sub', Menusub: true, active: false, children: [
				{ path: '/dashboard/reprocess-individual', title: 'Reprocessar individual', type: 'link' },
				{ path: '/dashboard/reprocess-by-batch', title: 'Reprocessar por lote', type: 'link' },
			]
		},

		{
			title: 'Caixas', icon: 'box', type: 'sub', Menusub: true, active: false, children: [
				{ path: '/dashboard/boxs', title: 'Caixas', type: 'link' },
				{ path: '/dashboard/awards-box', title: 'Caixas premiadas', type: 'link' },
			]
		},



		{
			headTitle: 'Sorteios',
		},

		// {
		// 	title: 'Rodada Especial', icon: 'activity', type: 'sub', Menusub: true, active: false, children: [
		// 		{ path: '/calender/defaultcalender', title: 'Rodadas', type: 'link' },
		// 	]
		// },

		{
			title: 'Sorteio', icon: 'file', type: 'sub', Menusub: true, active: false, children: [
				{ path: '/dashboard/search', title: 'Números premiados', type: 'link' },
				// { path: '/calender/defaultcalender', title: 'Calendario', type: 'link' },
				{ path: '/dashboard/draws', title: 'Sorteios', type: 'link' },
				{ path: '/dashboard/simulate-draw', title: 'Simular Sorteio', type: 'link' },
			]
		},

		{ title: 'Links externos', icon: 'external-link', type: 'link', Menusub: false, active: false, path: '/dashboard/external-links' }


	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
