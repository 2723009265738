<app-page-header title="Pages"  active_item="Switcher"></app-page-header>
<div class="container component-switcher">
  <div class="row">
      <div class="col-lg-6 m-auto">
          <div class="card sidebar-right1">
            <div class="card-body">
              <div class="predefined_styles">
                <div class="swichermainleft text-center">
                  <h4>LTR and RTL VERSIONS</h4>
                </div>
              <div class="switch_section">
                  <div class="switch-toggle d-flex">
                      <span class="me-auto">LTR</span>
                      <p class="onoffswitch2 my-0">
                          <input
                            type="radio"
                            name="onoffswitch25"
                            id="myonoffswitch54"
                            class="onoffswitch2-checkbox"
                            checked
                          />
                          <label
                            for="myonoffswitch54"
                            class="onoffswitch2-label"
                          ></label>
                        </p>
                  </div>
                  <div class="switch-toggle d-flex mt-2">
                      <span class="me-auto">RTL</span>
                      <p class="onoffswitch2 my-0">
                          <input
                            type="radio"
                            name="onoffswitch25"
                            id="myonoffswitch55"
                            class="onoffswitch2-checkbox"
                          />
                          <label
                            for="myonoffswitch55"
                            class="onoffswitch2-label"
                          ></label>
                        </p>
                  </div>
              </div>
             <div class="swichermainleft">
                    <h4>Theme Style</h4>
                    </div>
                <div class="switch_section">
                    <div class="switch-toggle d-flex">
                        <span class="me-auto">Light Theme</span>
                        <p class="onoffswitch2 my-0" (click)="LightTheme()">
                          <input
                            type="radio"
                            name="onoffswitch1"
                            id="myonoffswitch1"
                            class="onoffswitch2-checkbox"
                          />
                          <label
                            for="myonoffswitch1"
                            class="onoffswitch2-label"
                          ></label>
                        </p>
                      </div>
                      <div class="switch-toggle d-flex mt-2">
                        <span class="me-auto">Dark Theme</span>
                        <p class="onoffswitch2 my-0" (click)="DarkTheme()">
                            <input
                              type="radio"
                              name="onoffswitch1"
                              id="myonoffswitch2"
                              class="onoffswitch2-checkbox"
                            />
                            <label
                              for="myonoffswitch2"
                              class="onoffswitch2-label"
                            ></label>
                          </p>
                    </div>
                    <div class="swichermainleft switcher-nav">
                          <h4>Navigation Style</h4>

                      <div class="skin-body">
                      <div class="switch_section">
                          <div class="switch-toggle d-flex">
                              <span class="me-auto">Vertical Menu</span>
                              <p class="onoffswitch2 my-0">
                                  <input
                                    type="radio"
                                    name="onoffswitch15"
                                    id="myonoffswitch34"
                                    class="onoffswitch2-checkbox"
                                    checked
                                  />
                                  <label
                                    for="myonoffswitch34"
                                    class="onoffswitch2-label"
                                  ></label>
                                </p>
                          </div>
                          <div class="switch-toggle d-flex mt-2">
                              <span class="me-auto">Horizontal Click Menu</span>
                              <p class="onoffswitch2 my-0">
                                  <input
                                    type="radio"
                                    name="onoffswitch15"
                                    id="myonoffswitch35"
                                    class="onoffswitch2-checkbox"
                                  />
                                  <label
                                    for="myonoffswitch35"
                                    class="onoffswitch2-label"
                                  ></label>
                                </p>
                          </div>
                          <div class="switch-toggle d-flex mt-2">
                              <span class="me-auto">Horizontal Hover Menu</span>
                              <p class="onoffswitch2 my-0">
                                  <input
                                    type="radio"
                                    name="onoffswitch15"
                                    id="myonoffswitch111"
                                    class="onoffswitch2-checkbox"
                                  />
                                  <label
                                    for="myonoffswitch111"
                                    class="onoffswitch2-label"
                                  ></label>
                                </p>
                          </div>
                      </div>
                      </div>
                    </div>
                             <div class="swichermainleft">
                        <h4> Theme Primary Color</h4>
                    </div>
                    <div class="switch_section">
                     <div class="switch-toggle d-flex">
                            <span class="me-auto"> Primary Color</span>
                            <div class="">
                              <input
                              class="color-primary-transparent"
                              id="transparentPrimaryColorID"
                              data-id="bg-color"
                              data-id1="bg-hover"
                              data-id2="bg-border"
                              data-id3="primary"
                              data-id4="primary"
                              data-id9="transparentcolor"
                              [style.background]="color3"
                              [cpAlphaChannel]="'disabled'"
                              [cpOutputFormat]="'hex'"
                              [(colorPicker)]="color3"
                              (cpSliderDragEnd)="dynamicTranparentPrimary($event)"
                            />
                              </div>
                        </div>
                        <div class="switch-toggle d-flex mt-2">
                            <span class="me-auto">Background Color</span>
                            <div class="">
                              <input class="dark-bg-body" id="darkBgColorID" data-id5="body" data-id6="theme" [style.background]="color3" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"  [(colorPicker)]="color3" (cpSliderDragEnd)="dynamicDarkBg($event)" />
                          </div>
                        </div>
                    </div>

                    <div class="swichermainleft leftmenu-styles">
                      <h4>Menu Styles</h4>
                    </div>
                  <div class="switch_section">
                      <div class="switch-toggle lightMenu d-flex">
                          <span class="me-auto">Light Menu</span>
                          <p class="onoffswitch2 my-0">
                              <input
                                type="radio"
                                name="onoffswitch2"
                                id="myonoffswitch3"
                                class="onoffswitch2-checkbox"
                                checked
                              />
                              <label
                                for="myonoffswitch3"
                                class="onoffswitch2-label"
                              ></label>
                            </p>
                      </div>
                      <div class="switch-toggle colorMenu d-flex mt-2">
                          <span class="me-auto">Color Menu</span>
                          <p class="onoffswitch2 my-0">
                              <input
                                type="radio"
                                name="onoffswitch2"
                                id="myonoffswitch4"
                                class="onoffswitch2-checkbox"
                              />
                              <label
                                for="myonoffswitch4"
                                class="onoffswitch2-label"
                              ></label>
                            </p>
                      </div>
                      <div class="switch-toggle darkMenu d-flex mt-2">
                          <span class="me-auto">Dark Menu</span>
                          <p class="onoffswitch2 my-0">
                              <input
                                type="radio"
                                name="onoffswitch2"
                                id="myonoffswitch5"
                                class="onoffswitch2-checkbox"
                              />
                              <label
                                for="myonoffswitch5"
                                class="onoffswitch2-label"
                              ></label>
                            </p>
                      </div>

                  </div>

                <div class="swichermainleft header-styles">
                      <h4 >Header Styles</h4>
                    </div>
                  <div class="switch_section">
                      <div class="switch-toggle lightHeader d-flex">
                          <span class="me-auto">Light Header</span>
                          <p class="onoffswitch2 my-0">
                              <input
                                type="radio"
                                name="onoffswitch3"
                                id="myonoffswitch6"
                                class="onoffswitch2-checkbox"
                                checked
                              />
                              <label
                                for="myonoffswitch6"
                                class="onoffswitch2-label"
                              ></label>
                            </p>
                      </div>
                      <div class="switch-toggle  colorHeader d-flex mt-2">
                          <span class="me-auto">Color Header</span>
                          <p class="onoffswitch2 my-0">
                              <input
                                type="radio"
                                name="onoffswitch3"
                                id="myonoffswitch7"
                                class="onoffswitch2-checkbox"
                              />
                              <label
                                for="myonoffswitch7"
                                class="onoffswitch2-label"
                              ></label>
                            </p>
                      </div>
                      <div class="switch-toggle darkHeader d-flex mt-2">
                          <span class="me-auto">Dark Header</span>
                          <p class="onoffswitch2 my-0">
                              <input
                                type="radio"
                                name="onoffswitch3"
                                id="myonoffswitch8"
                                class="onoffswitch2-checkbox"
                              />
                              <label
                                for="myonoffswitch8"
                                class="onoffswitch2-label"
                              ></label>
                            </p>
                      </div>


                  </div>
                  <div class="swichermainleft">
                      <h4>Layout Width Styles</h4>
                  </div>
                  <div class="switch_section">
                      <div class="switch-toggle d-flex">
                          <span class="me-auto">Full Width</span>
                          <p class="onoffswitch2 my-0">
                              <input
                                type="radio"
                                name="onoffswitch4"
                                id="myonoffswitch9"
                                class="onoffswitch2-checkbox"
                                checked
                              />
                              <label
                                for="myonoffswitch9"
                                class="onoffswitch2-label"
                              ></label>
                            </p>
                      </div>
                      <div class="switch-toggle d-flex mt-2">
                          <span class="me-auto">Boxed</span>
                          <p class="onoffswitch2 my-0">
                              <input
                                type="radio"
                                name="onoffswitch4"
                                id="myonoffswitch10"
                                class="onoffswitch2-checkbox"
                              />
                              <label
                                for="myonoffswitch10"
                                class="onoffswitch2-label"
                              ></label>
                            </p>
                      </div>
                  </div>


                	<div class="swichermainleft">
                      <h4>Layout Positions</h4>
                  </div>
                  <div class="switch_section">
                      <div class="switch-toggle d-flex">
                          <span class="me-auto">Fixed</span>
                          <p class="onoffswitch2 my-0">
                              <input
                                type="radio"
                                name="onoffswitch5"
                                id="myonoffswitch11"
                                class="onoffswitch2-checkbox"
                                checked
                             />
                              <label
                                for="myonoffswitch11"
                                class="onoffswitch2-label"
                              ></label>
                            </p>
                      </div>
                      <div class="switch-toggle d-flex mt-2">
                          <span class="me-auto">Scrollable</span>
                          <p class="onoffswitch2 my-0">
                              <input
                                type="radio"
                                name="onoffswitch5"
                                id="myonoffswitch12"
                                class="onoffswitch2-checkbox"
                              />
                              <label
                                for="myonoffswitch12"
                                class="onoffswitch2-label"
                              ></label>
                            </p>
                      </div>
                  </div>

            </div>

            <div class="swichermainleft">
              <h4>Reset All Styles</h4>
              </div>
                  <div class="switch_section  my-4">
                      <div class="btn-list">
                          <button class="btn btn-danger btn-block" (click)="reset()">Reset All
                      </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
