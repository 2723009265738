<!-- Switcher -->

<div #switcher class="switcher-wrapper">
  <div class="demo_changer">

    <div class="form_holder sidebar-right1">

      <div class="row">

        <div class="predefined_styles">
          <!-- <ng-scrollbar> -->
          <div class="swichermainleft text-center">
            <h4>LTR and RTL VERSIONS</h4>
          </div>
          <div class="switch_section">
            <div class="switch-toggle d-flex">
              <span class="me-auto">LTR</span>
              <p class="onoffswitch2 my-0">
                <input type="radio" name="onoffswitch25" id="myonoffswitch54" class="onoffswitch2-checkbox" checked />
                <label for="myonoffswitch54" class="onoffswitch2-label"></label>
              </p>
            </div>
            <div class="switch-toggle d-flex mt-2">
              <span class="me-auto">RTL</span>
              <p class="onoffswitch2 my-0">
                <input type="radio" name="onoffswitch25" id="myonoffswitch55" class="onoffswitch2-checkbox" />
                <label for="myonoffswitch55" class="onoffswitch2-label"></label>
              </p>
            </div>
          </div>
          <!-- </ng-scrollbar> -->
          <div class="swichermainleft">
            <h4>Theme Style</h4>
          </div>
          <div class="switch_section px-0">
            <div class="switch-toggle d-flex">
              <span class="me-auto">Light Theme</span>
              <p class="onoffswitch2 my-0" (click)="LightTheme()">
                <input type="radio" name="onoffswitch1" id="myonoffswitch1" class="onoffswitch2-checkbox" />
                <label for="myonoffswitch1" class="onoffswitch2-label"></label>
              </p>
            </div>
            <div class="switch-toggle d-flex mt-2">
              <span class="me-auto">Dark Theme</span>
              <p class="onoffswitch2 my-0"(click)="DarkTheme()" >
                <input type="radio" name="onoffswitch1" id="myonoffswitch2" class="onoffswitch2-checkbox" />
                <label for="myonoffswitch2" class="onoffswitch2-label"></label>
              </p>
            </div>
            <div class="swichermainleft switcher-nav">
              <h4>Navigation Style</h4>

              <div class="skin-body">
                <div class="switch_section">
                  <div class="switch-toggle d-flex">
                    <span class="me-auto">Vertical Menu</span>
                    <p class="onoffswitch2 my-0">
                      <input type="radio" name="onoffswitch15" id="myonoffswitch34" class="onoffswitch2-checkbox"
                        checked />
                      <label for="myonoffswitch34" class="onoffswitch2-label"></label>
                    </p>
                  </div>
                  <div class="switch-toggle d-flex mt-2">
                    <span class="me-auto">Horizontal Click Menu</span>
                    <p class="onoffswitch2 my-0">
                      <input type="radio" name="onoffswitch15" id="myonoffswitch35" class="onoffswitch2-checkbox" />
                      <label for="myonoffswitch35" class="onoffswitch2-label"></label>
                    </p>
                  </div>
                  <div class="switch-toggle d-flex mt-2">
                    <span class="me-auto">Horizontal Hover Menu</span>
                    <p class="onoffswitch2 my-0">
                      <input type="radio" name="onoffswitch15" id="myonoffswitch111" class="onoffswitch2-checkbox" />
                      <label for="myonoffswitch111" class="onoffswitch2-label"></label>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="swichermainleft">
              <h4> Theme Primary Color</h4>
            </div>
            <div class="switch_section">
              <div class="switch-toggle d-flex">
                <span class="me-auto"> Primary Color</span>
                <div class="">
                  <input class="color-primary-transparent" id="transparentPrimaryColorID" data-id="bg-color"
                    data-id1="bg-hover" data-id2="bg-border" data-id3="primary" data-id4="primary"
                    data-id9="transparentcolor" [style.background]="color3" [cpAlphaChannel]="'disabled'"
                    [cpOutputFormat]="'hex'" [(colorPicker)]="color3"
                    (cpSliderDragEnd)="dynamicTranparentPrimary($event)" />

                </div>
              </div>
              <div class="switch-toggle d-flex mt-2">
                <span class="me-auto">Background Color</span>
                <div class="">
                  <input class="dark-bg-body" id="darkBgColorID" data-id5="body" data-id6="theme"
                    [style.background]="color3" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                    [(colorPicker)]="color3" (cpSliderDragEnd)="dynamicDarkBg($event)" />
                </div>
              </div>

              <div class="swichermainleft leftmenu-styles">
                <h4>Menu Styles</h4>
              </div>
              <div class="switch_section">
                <div class="switch-toggle lightMenu d-flex">
                  <span class="me-auto">Light Menu</span>
                  <p class="onoffswitch2 my-0">
                    <input type="radio" name="onoffswitch2" id="myonoffswitch3" class="onoffswitch2-checkbox" checked />
                    <label for="myonoffswitch3" class="onoffswitch2-label"></label>
                  </p>
                </div>
                <div class="switch-toggle colorMenu d-flex mt-2">
                  <span class="me-auto">Color Menu</span>
                  <p class="onoffswitch2 my-0">
                    <input type="radio" name="onoffswitch2" id="myonoffswitch4" class="onoffswitch2-checkbox" />
                    <label for="myonoffswitch4" class="onoffswitch2-label"></label>
                  </p>
                </div>
                <div class="switch-toggle darkMenu d-flex mt-2">
                  <span class="me-auto">Dark Menu</span>
                  <p class="onoffswitch2 my-0">
                    <input type="radio" name="onoffswitch2" id="myonoffswitch5" class="onoffswitch2-checkbox" />
                    <label for="myonoffswitch5" class="onoffswitch2-label"></label>
                  </p>
                </div>

              </div>

              <div class="swichermainleft header-styles">
                <h4>Header Styles</h4>
              </div>
              <div class="switch_section">
                <div class="switch-toggle lightHeader d-flex">
                  <span class="me-auto">Light Header</span>
                  <p class="onoffswitch2 my-0">
                    <input type="radio" name="onoffswitch3" id="myonoffswitch6" class="onoffswitch2-checkbox" checked />
                    <label for="myonoffswitch6" class="onoffswitch2-label"></label>
                  </p>
                </div>
                <div class="switch-toggle  colorHeader d-flex mt-2">
                  <span class="me-auto">Color Header</span>
                  <p class="onoffswitch2 my-0">
                    <input type="radio" name="onoffswitch3" id="myonoffswitch7" class="onoffswitch2-checkbox" />
                    <label for="myonoffswitch7" class="onoffswitch2-label"></label>
                  </p>
                </div>
                <div class="switch-toggle darkHeader d-flex mt-2">
                  <span class="me-auto">Dark Header</span>
                  <p class="onoffswitch2 my-0">
                    <input type="radio" name="onoffswitch3" id="myonoffswitch8" class="onoffswitch2-checkbox" />
                    <label for="myonoffswitch8" class="onoffswitch2-label"></label>
                  </p>
                </div>


              </div>
              <div class="swichermainleft">
                <h4>Layout Width Styles</h4>
              </div>
              <div class="switch_section">
                <div class="switch-toggle d-flex">
                  <span class="me-auto">Full Width</span>
                  <p class="onoffswitch2 my-0">
                    <input type="radio" name="onoffswitch4" id="myonoffswitch9" class="onoffswitch2-checkbox" checked />
                    <label for="myonoffswitch9" class="onoffswitch2-label"></label>
                  </p>
                </div>
                <div class="switch-toggle d-flex mt-2">
                  <span class="me-auto">Boxed</span>
                  <p class="onoffswitch2 my-0">
                    <input type="radio" name="onoffswitch4" id="myonoffswitch10" class="onoffswitch2-checkbox" />
                    <label for="myonoffswitch10" class="onoffswitch2-label"></label>
                  </p>
                </div>
              </div>


              <div class="swichermainleft">
                <h4>Layout Positions</h4>
              </div>
              <div class="switch_section">
                <div class="switch-toggle d-flex">
                  <span class="me-auto">Fixed</span>
                  <p class="onoffswitch2 my-0">
                    <input type="radio" name="onoffswitch5" id="myonoffswitch11" class="onoffswitch2-checkbox"
                      checked />
                    <label for="myonoffswitch11" class="onoffswitch2-label"></label>
                  </p>
                </div>
                <div class="switch-toggle d-flex mt-2">
                  <span class="me-auto">Scrollable</span>
                  <p class="onoffswitch2 my-0">
                    <input type="radio" name="onoffswitch5" id="myonoffswitch12" class="onoffswitch2-checkbox" />
                    <label for="myonoffswitch12" class="onoffswitch2-label"></label>
                  </p>
                </div>
              </div>

            </div>

            <div class="swichermainleft">
              <h4>Reset All Styles</h4>
            </div>
            <div class="switch_section  my-4">
              <div class="btn-list">
                <button class="btn btn-danger btn-block" (click)="reset()">Reset All
                </button>
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
</div>


<!-- End Switcher -->
<ng-template #content let-modal>
  <!-- <div class="modal buynow show" aria-modal="true" role="dialog" style="display: block;"> -->
      <div class=" buynow" role="document">
          <div class=" modal-content-demo buynow-bg" data-bs-image-src="./assets/switcher/img/16.jpg" style="background: url(&quot;./assets/switcher/img/16.jpg&quot;) center center;">
              <div class="modal-body  px-0">
                  <div class="row justify-content-center py-4 px-0 mx-3  Licenses-img">
                      <h3 class=" text-center mb-4 text-white">Licenses</h3>
                      <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true" (click)="modal.dismiss('Cross click')">×</span></button>
                          <div class="col-sm-10 col-md-8 col-xl-5 col-lg-5">
                              <div class="card  border-0 regular-license">
                                  <div class="card-body imag-list cover-image" data-bs-image-src="./assets/switcher/img/14.jpg" style="background: url(&quot;./assets/switcher/img/14.jpg&quot;) center center;">
                                      <div class="text-white">
                                          <img src="./assets/switcher/img/free.png" alt="" class="w-55 free-img">
                                          <div class="text-center">
                                              <div class="tx-26"><span class="fw-medium ">Regular</span> Licenses</div>
                                              <p class="fw-semi-bold mb-sm-2 mb-0">You <span class="text-success fw-semibold">can't charge </span> from your <br><span class="op-8">End Product  End Users</span> </p>
                                              <div ngbDropdown  class="dropdown">
                                                  <button ngbDropdownToggle class="btn btn-info w-lg dropdown-toggle mt-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                      Buy Now
                                                  </button>
                                                  <ul ngbDropdownMenu  class="dropdown-menu py-0">
                                                      <a ngbDropdownItem class="dropdown-item  border-bottom px-3" target="_blank" href="https://themeforest.net/user/spruko/portfolio">
                                                          <div>
                                                              <p class="tx-14 mb-0 lh-xs fw-semibold">Buy Now</p><span class="tx-12 op-7 ">6 months support</span>
                                                          </div>
                                                      </a>
                                                      <a ngbDropdownItem class="dropdown-item   px-3" target="_blank" href="https://themeforest.net/user/spruko/portfolio">
                                                          <div>
                                                              <p class="tx-14 mb-0 lh-xs fw-semibold">Buy Now</p><span class="tx-12 op-7 ">12 months support</span>
                                                          </div>
                                                      </a>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-10 col-md-8 col-xl-5 col-lg-5">
                          <div class="card border-0 ">
                              <div class="card-body imag-list cover-image" data-bs-image-src="./assets/switcher/img/15.jpg" style="background: url(&quot;./assets/switcher/img/15.jpg&quot;) center center;">
                                  <div class="text-white">
                                      <img src="./assets/switcher/img/money-bag.png" alt="" class="w-55 free-img">
                                      <div class="text-center">
                                          <div class="tx-26"><span class="fw-medium ">Extended</span> Licenses</div>
                                          <p class="fw-semi-bold mb-sm-2 mb-0">You  <span class="text-warning fw-semibold">can charge</span> from your  <br><span class="op-8">End Product  End Users</span></p>
                                          <div ngbDropdown class="dropdown">
                                              <button ngbDropdownToggle class="btn btn-info w-lg dropdown-toggle mt-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                  Buy Now
                                              </button>
                                              <ul ngbDropdownMenu class="dropdown-menu py-0">
                                                  <a ngbDropdownItem class="dropdown-item  border-bottom px-3" target="_blank" href="https://themeforest.net/user/spruko/portfolio">
                                                      <div>
                                                          <p class="tx-14 mb-0 lh-xs fw-semibold">Buy Now</p><span class="tx-12 op-7 ">6 months support</span>
                                                      </div>
                                                  </a>
                                                  <a ngbDropdownItem class="dropdown-item   px-3" target="_blank" href="https://themeforest.net/user/spruko/portfolio">
                                                      <div>
                                                          <p class="tx-14 mb-0 lh-xs fw-semibold">Buy Now</p><span class="tx-12 op-7 ">12 months support</span>
                                                      </div>
                                                  </a>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          </div>
                          <div class="license-view">
                              <a href="https://spruko.com/licenses" target="_blank" class="modal-title text-center mb-3 tx-14 text-white">View license details</a>
                          </div>
                       </div>
               </div>
          </div>
      </div>
  <!-- </div> -->
</ng-template>
