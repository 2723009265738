<!-- Sidebar-right-->
<div #sidebar class="sidebar sidebar-right sidebar-animate">
  <ng-scrollbar>
    <div class="panel panel-primary card mb-0 shadow-none border-0">
      <!-- <div class="tab-menu-heading border-0 d-flex p-3">
        <div class="card-options ms-auto">
            <a (click)="onClose()" href="javascript:void(0);" class="sidebar-icon text-end float-end me-3 mb-1" data-bs-toggle="sidebar-right" data-target=".sidebar-right"><i class="fe fe-x text-white"></i></a>
        </div>
      </div> -->

      <div class="p-2 pe-3 mb-2 sidebar-icon">
				<a (click)="onClose()" class="text-end float-end" data-bs-toggle="sidebar-right" data-bs-target=".sidebar-right"><i class="fe fe-x"></i></a>
			</div>

      <div class="panel-body tabs-menu-body latest-tasks p-0 border-0">

        <div class="tabs-menu ">
          <!-- Tabs -->
          <ul ngbNav #nav="ngbNav" [activeId]="1" class=" panel-tabs">
            <li [ngbNavItem]="1">
                    <a ngbNavLink href="#side1"  data-bs-toggle="tab">Settings</a>
              <ng-template ngbNavContent>
                <div class="panel-body tabs-menu-body side-tab-body p-0 border-0 ">
                  <div class="tab-content border-top">
                    <div class="tab-pane active" id="tab1">
                      <div class="p-3 border-bottom">
                        <h5 class="border-bottom-0 mb-0">General Settings</h5>
                      </div>
                      <div class="p-4">
                        <div class="switch-settings">
                          <div class="d-flex mb-2">
                            <span class="me-auto fs-15">Notifications</span>
                            <div class="onoffswitch2">
                              <input type="checkbox" name="onoffswitch2" id="onoffswitch" class="onoffswitch2-checkbox" checked="">
                              <label for="onoffswitch" class="onoffswitch2-label"></label>
                            </div>
                          </div>
                          <div class="d-flex mb-2">
                            <span class="me-auto fs-15">Show your emails</span>
                            <div class="onoffswitch2">
                              <input type="checkbox" name="onoffswitch2" id="onoffswitch1" class="onoffswitch2-checkbox">
                              <label for="onoffswitch1" class="onoffswitch2-label"></label>
                            </div>
                          </div>
                          <div class="d-flex mb-2">
                            <span class="me-auto fs-15">Show Task statistics</span>
                            <div class="onoffswitch2">
                              <input type="checkbox" name="onoffswitch2" id="onoffswitch2" class="onoffswitch2-checkbox">
                              <label for="onoffswitch2" class="onoffswitch2-label"></label>
                            </div>
                          </div>
                          <div class="d-flex mb-2">
                            <span class="me-auto fs-15">Show recent activity</span>
                            <div class="onoffswitch2">
                              <input type="checkbox" name="onoffswitch2" id="onoffswitch3" class="onoffswitch2-checkbox" checked="">
                              <label for="onoffswitch3" class="onoffswitch2-label"></label>
                            </div>
                          </div>
                          <div class="d-flex mb-2">
                            <span class="me-auto fs-15">System Logs</span>
                            <div class="onoffswitch2">
                              <input type="checkbox" name="onoffswitch2" id="onoffswitch4" class="onoffswitch2-checkbox">
                              <label for="onoffswitch4" class="onoffswitch2-label"></label>
                            </div>
                          </div>
                          <div class="d-flex mb-2">
                            <span class="me-auto fs-15">Error Reporting</span>
                            <div class="onoffswitch2">
                              <input type="checkbox" name="onoffswitch2" id="onoffswitch5" class="onoffswitch2-checkbox">
                              <label for="onoffswitch5" class="onoffswitch2-label"></label>
                            </div>
                          </div>
                          <div class="d-flex mb-2">
                            <span class="me-auto fs-15">Show your status to all</span>
                            <div class="onoffswitch2">
                              <input type="checkbox" name="onoffswitch2" id="onoffswitch6" class="onoffswitch2-checkbox" checked="">
                              <label for="onoffswitch6" class="onoffswitch2-label"></label>
                            </div>
                          </div>
                          <div class="d-flex mb-2">
                            <span class="me-auto fs-15">Keep up to date</span>
                            <div class="onoffswitch2">
                              <input type="checkbox" name="onoffswitch2" id="onoffswitch7" class="onoffswitch2-checkbox">
                              <label for="onoffswitch7" class="onoffswitch2-label"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="p-3 border-bottom">
                        <h5 class="border-bottom-0 mb-0">Overview</h5>
                      </div>
                      <div class="p-4">
                        <div class="progress-wrapper">
                          <div class="mb-3">
                            <p class="mb-2">Achieves<span class="float-end text-muted font-weight-500">80%</span></p>
                            <div class="progress h-1">
                              <div class="progress-bar bg-primary w-80 " role="progressbar"></div>
                            </div>
                          </div>
                        </div>
                        <div class="progress-wrapper pt-2">
                          <div class="mb-3">
                            <p class="mb-2">Projects<span class="float-end text-muted font-weight-500">60%</span></p>
                            <div class="progress h-1">
                              <div class="progress-bar bg-secondary w-60 " role="progressbar"></div>
                            </div>
                          </div>
                        </div>
                        <div class="progress-wrapper pt-2">
                          <div class="mb-3">
                            <p class="mb-2">Earnings<span class="float-end text-muted font-weight-500">50%</span></p>
                            <div class="progress h-1">
                              <div class="progress-bar bg-success w-50" role="progressbar"></div>
                            </div>
                          </div>
                        </div>
                        <div class="progress-wrapper pt-2">
                          <div class="mb-3">
                            <p class="mb-2">Balance<span class="float-end text-muted font-weight-500">45%</span></p>
                            <div class="progress h-1">
                              <div class="progress-bar bg-warning w-45 " role="progressbar"></div>
                            </div>
                          </div>
                        </div>
                        <div class="progress-wrapper pt-2">
                          <div class="mb-3">
                            <p class="mb-2">Toatal Profits<span class="float-end text-muted font-weight-500">75%</span></p>
                            <div class="progress h-1">
                              <div class="progress-bar bg-danger w-75" role="progressbar"></div>
                            </div>
                          </div>
                        </div>
                        <div class="progress-wrapper pt-2">
                          <div class="mb-3">
                            <p class="mb-2">Total Likes<span class="float-end text-muted font-weight-500">70%</span></p>
                            <div class="progress h-1">
                              <div class="progress-bar bg-teal w-70" role="progressbar"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="tab2">
                      <div class="list-group-item d-flex  align-items-center border-top-0">
                        <div class="me-2">
                          <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/female/2.jpg" style="background: url(&quot;./assets/images/users/female/2.jpg&quot;) center center;"></span>
                        </div>
                        <div class="">
                          <div class="font-weight-500">Mozelle Belt</div>
                          <small class="text-muted">Web Designer
                          </small>
                        </div>
                        <div class="ms-auto">
                          <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                        </div>
                      </div>
                      <div class="list-group-item d-flex  align-items-center">
                        <div class="me-2">
                          <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/female/6.jpg" style="background: url(&quot;./assets/images/users/female/6.jpg&quot;) center center;"></span>
                        </div>
                        <div class="">
                          <div class="font-weight-500">Alina Bernier</div>
                          <small class="text-muted">Administrator
                          </small>
                        </div>
                        <div class="ms-auto">
                          <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                        </div>
                      </div>
                      <div class="list-group-item d-flex  align-items-center">
                        <div class="me-2">
                          <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/male/5.jpg" style="background: url(&quot;./assets/images/users/male/5.jpg&quot;) center center;"></span>
                        </div>
                        <div class="">
                          <div class="font-weight-500">Isidro Heide</div>
                          <small class="text-muted">Web Designer
                          </small>
                        </div>
                        <div class="ms-auto">
                          <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                        </div>
                      </div>
                      <div class="list-group-item d-flex  align-items-center">
                        <div class="me-2">
                          <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/male/6.jpg" style="background: url(&quot;./assets/images/users/male/6.jpg&quot;) center center;"></span>
                        </div>
                        <div class="">
                          <div class="font-weight-500">Isidro Heide</div>
                          <small class="text-muted">Web Designer
                          </small>
                        </div>
                        <div class="ms-auto">
                          <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                        </div>
                      </div>
                      <div class="list-group-item d-flex  align-items-center">
                        <div class="me-2">
                          <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/male/2.jpg" style="background: url(&quot;./assets/images/users/male/2.jpg&quot;) center center;"></span>
                        </div>
                        <div class="">
                          <div class="font-weight-500">Isidro Heide</div>
                          <small class="text-muted">Web Designer
                          </small>
                        </div>
                        <div class="ms-auto">
                          <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                        </div>
                      </div>
                      <div class="list-group-item d-flex  align-items-center">
                        <div class="me-2">
                          <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/male/4.jpg" style="background: url(&quot;./assets/images/users/male/4.jpg&quot;) center center;"></span>
                        </div>
                        <div class="">
                          <div class="font-weight-500">Isidro Heide</div>
                          <small class="text-muted">Web Designer
                          </small>
                        </div>
                        <div class="ms-auto">
                          <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                        </div>
                      </div>
                      <div class="list-group-item d-flex  align-items-center">
                        <div class="me-2">
                          <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/male/5.jpg" style="background: url(&quot;./assets/images/users/male/5.jpg&quot;) center center;"></span>
                        </div>
                        <div class="">
                          <div class="font-weight-500">Isidro Heide</div>
                          <small class="text-muted">Web Designer
                          </small>
                        </div>
                        <div class="ms-auto">
                          <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                        </div>
                      </div>
                      <div class="list-group-item d-flex  align-items-center">
                        <div class="me-2">
                          <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/male/2.jpg" style="background: url(&quot;./assets/images/users/male/2.jpg&quot;) center center;"></span>
                        </div>
                        <div class="">
                          <div class="font-weight-500">Isidro Heide</div>
                          <small class="text-muted">Web Designer
                          </small>
                        </div>
                        <div class="ms-auto">
                          <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                        </div>
                      </div>
                      <div class="list-group-item d-flex  align-items-center">
                        <div class="me-2">
                          <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/female/3.jpg" style="background: url(&quot;./assets/images/users/female/3.jpg&quot;) center center;"></span>
                        </div>
                        <div class="">
                          <div class="font-weight-500">Florinda Carasco</div>
                          <small class="text-muted">Project Manager
                          </small>
                        </div>
                        <div class="ms-auto">
                          <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="tab3">
                      <div class="">
                        <div class="d-flex p-3">
                          <label class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" name="example-checkbox1" value="option1" checked="">
                            <span class="custom-control-label">Do Even More..</span>
                          </label>
                          <span class="ms-auto">
                            <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                            <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                          </span>
                        </div>
                        <div class="d-flex p-3 border-top">
                          <label class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" name="example-checkbox2" value="option2" checked="">
                            <span class="custom-control-label">Find an idea.</span>
                          </label>
                          <span class="ms-auto">
                            <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                            <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                          </span>
                        </div>
                        <div class="d-flex p-3 border-top">
                          <label class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" name="example-checkbox3" value="option3" checked="">
                            <span class="custom-control-label">Hangout with friends</span>
                          </label>
                          <span class="ms-auto">
                            <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                            <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                          </span>
                        </div>
                        <div class="d-flex p-3 border-top">
                          <label class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" name="example-checkbox4" value="option4">
                            <span class="custom-control-label">Do Something else</span>
                          </label>
                          <span class="ms-auto">
                            <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                            <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                          </span>
                        </div>
                        <div class="d-flex p-3 border-top">
                          <label class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" name="example-checkbox5" value="option5">
                            <span class="custom-control-label">Eat healthy, Eat Fresh..</span>
                          </label>
                          <span class="ms-auto">
                            <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                            <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                          </span>
                        </div>
                        <div class="d-flex p-3 border-top">
                          <label class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" name="example-checkbox6" value="option6" checked="">
                            <span class="custom-control-label">Finsh something more..</span>
                          </label>
                          <span class="ms-auto">
                            <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                            <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                          </span>
                        </div>
                        <div class="d-flex p-3 border-top">
                          <label class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" name="example-checkbox7" value="option7" checked="">
                            <span class="custom-control-label">Do something more</span>
                          </label>
                          <span class="ms-auto">
                            <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                            <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                          </span>
                        </div>
                        <div class="d-flex p-3 border-top">
                          <label class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" name="example-checkbox8" value="option8">
                            <span class="custom-control-label">Updated more files</span>
                          </label>
                          <span class="ms-auto">
                            <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                            <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                          </span>
                        </div>
                        <div class="d-flex p-3 border-top">
                          <label class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" name="example-checkbox9" value="option9">
                            <span class="custom-control-label">System updated</span>
                          </label>
                          <span class="ms-auto">
                            <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                            <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                          </span>
                        </div>
                        <div class="d-flex p-3 border-top">
                          <label class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" name="example-checkbox10" value="option10">
                            <span class="custom-control-label">Settings Changings...</span>
                          </label>
                          <span class="ms-auto">
                            <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                            <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                          </span>
                        </div><div class="d-flex p-3 border-top">
                          <label class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" name="example-checkbox9" value="option9">
                            <span class="custom-control-label">System updated</span>
                          </label>
                          <span class="ms-auto">
                            <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                            <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                          </span>
                        </div>
                        <div class="d-flex p-3 border-top border-bottom">
                          <label class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" name="example-checkbox10" value="option10">
                            <span class="custom-control-label">Settings Changings...</span>
                          </label>
                          <span class="ms-auto">
                            <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                            <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
                  <a ngbNavLink href="#side2" data-bs-toggle="tab"> Followers</a>
              <ng-template ngbNavContent>
                <div class="tab-pane active" id="tab2">
                  <div class="list-group-item d-flex  align-items-center ">
                    <div class="me-2">
                      <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/female/2.jpg" style="background: url(&quot;./assets/images/users/female/2.jpg&quot;) center center;"></span>
                    </div>
                    <div class="">
                      <div class="font-weight-500">Mozelle Belt</div>
                      <small class="text-muted">Web Designer
                      </small>
                    </div>
                    <div class="ms-auto">
                      <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                    </div>
                  </div>
                  <div class="list-group-item d-flex  align-items-center">
                    <div class="me-2">
                      <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/female/6.jpg" style="background: url(&quot;./assets/images/users/female/6.jpg&quot;) center center;"></span>
                    </div>
                    <div class="">
                      <div class="font-weight-500">Alina Bernier</div>
                      <small class="text-muted">Administrator
                      </small>
                    </div>
                    <div class="ms-auto">
                      <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                    </div>
                  </div>
                  <div class="list-group-item d-flex  align-items-center">
                    <div class="me-2">
                      <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/male/5.jpg" style="background: url(&quot;./assets/images/users/male/5.jpg&quot;) center center;"></span>
                    </div>
                    <div class="">
                      <div class="font-weight-500">Isidro Heide</div>
                      <small class="text-muted">Web Designer
                      </small>
                    </div>
                    <div class="ms-auto">
                      <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                    </div>
                  </div>
                  <div class="list-group-item d-flex  align-items-center">
                    <div class="me-2">
                      <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/male/6.jpg" style="background: url(&quot;./assets/images/users/male/6.jpg&quot;) center center;"></span>
                    </div>
                    <div class="">
                      <div class="font-weight-500">Isidro Heide</div>
                      <small class="text-muted">Web Designer
                      </small>
                    </div>
                    <div class="ms-auto">
                      <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                    </div>
                  </div>
                  <div class="list-group-item d-flex  align-items-center">
                    <div class="me-2">
                      <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/male/2.jpg" style="background: url(&quot;./assets/images/users/male/2.jpg&quot;) center center;"></span>
                    </div>
                    <div class="">
                      <div class="font-weight-500">Isidro Heide</div>
                      <small class="text-muted">Web Designer
                      </small>
                    </div>
                    <div class="ms-auto">
                      <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                    </div>
                  </div>
                  <div class="list-group-item d-flex  align-items-center">
                    <div class="me-2">
                      <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/male/4.jpg" style="background: url(&quot;./assets/images/users/male/4.jpg&quot;) center center;"></span>
                    </div>
                    <div class="">
                      <div class="font-weight-500">Isidro Heide</div>
                      <small class="text-muted">Web Designer
                      </small>
                    </div>
                    <div class="ms-auto">
                      <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                    </div>
                  </div>
                  <div class="list-group-item d-flex  align-items-center">
                    <div class="me-2">
                      <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/male/5.jpg" style="background: url(&quot;./assets/images/users/male/5.jpg&quot;) center center;"></span>
                    </div>
                    <div class="">
                      <div class="font-weight-500">Isidro Heide</div>
                      <small class="text-muted">Web Designer
                      </small>
                    </div>
                    <div class="ms-auto">
                      <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                    </div>
                  </div>
                  <div class="list-group-item d-flex  align-items-center">
                    <div class="me-2">
                      <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/male/2.jpg" style="background: url(&quot;./assets/images/users/male/2.jpg&quot;) center center;"></span>
                    </div>
                    <div class="">
                      <div class="font-weight-500">Isidro Heide</div>
                      <small class="text-muted">Web Designer
                      </small>
                    </div>
                    <div class="ms-auto">
                      <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                    </div>
                  </div>
                  <div class="list-group-item d-flex  align-items-center">
                    <div class="me-2">
                      <span class="avatar avatar-md brround cover-image" data-image-src="./assets/images/users/female/3.jpg" style="background: url(&quot;./assets/images/users/female/3.jpg&quot;) center center;"></span>
                    </div>
                    <div class="">
                      <div class="font-weight-500">Florinda Carasco</div>
                      <small class="text-muted">Project Manager
                      </small>
                    </div>
                    <div class="ms-auto">
                      <a href="javascript:void(0)" class="btn btn-sm  btn-light">Follow</a>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
                  <a ngbNavLink  data-bs-toggle="tab">Todo</a>
              <ng-template ngbNavContent>
                <div class="tab-pane active" id="tab3">
                  <div class="">
                    <div class="d-flex p-3">
                      <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" name="example-checkbox1" value="option1" checked="">
                        <span class="custom-control-label">Do Even More..</span>
                      </label>
                      <span class="ms-auto">
                        <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                        <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                      </span>
                    </div>
                    <div class="d-flex p-3 border-top">
                      <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" name="example-checkbox2" value="option2" checked="">
                        <span class="custom-control-label">Find an idea.</span>
                      </label>
                      <span class="ms-auto">
                        <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                        <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                      </span>
                    </div>
                    <div class="d-flex p-3 border-top">
                      <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" name="example-checkbox3" value="option3" checked="">
                        <span class="custom-control-label">Hangout with friends</span>
                      </label>
                      <span class="ms-auto">
                        <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                        <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                      </span>
                    </div>
                    <div class="d-flex p-3 border-top">
                      <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" name="example-checkbox4" value="option4">
                        <span class="custom-control-label">Do Something else</span>
                      </label>
                      <span class="ms-auto">
                        <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                        <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                      </span>
                    </div>
                    <div class="d-flex p-3 border-top">
                      <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" name="example-checkbox5" value="option5">
                        <span class="custom-control-label">Eat healthy, Eat Fresh..</span>
                      </label>
                      <span class="ms-auto">
                        <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                        <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                      </span>
                    </div>
                    <div class="d-flex p-3 border-top">
                      <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" name="example-checkbox6" value="option6" checked="">
                        <span class="custom-control-label">Finsh something more..</span>
                      </label>
                      <span class="ms-auto">
                        <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                        <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                      </span>
                    </div>
                    <div class="d-flex p-3 border-top">
                      <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" name="example-checkbox7" value="option7" checked="">
                        <span class="custom-control-label">Do something more</span>
                      </label>
                      <span class="ms-auto">
                        <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                        <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                      </span>
                    </div>
                    <div class="d-flex p-3 border-top">
                      <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" name="example-checkbox8" value="option8">
                        <span class="custom-control-label">Updated more files</span>
                      </label>
                      <span class="ms-auto">
                        <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                        <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                      </span>
                    </div>
                    <div class="d-flex p-3 border-top">
                      <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" name="example-checkbox9" value="option9">
                        <span class="custom-control-label">System updated</span>
                      </label>
                      <span class="ms-auto">
                        <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                        <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                      </span>
                    </div>
                    <div class="d-flex p-3 border-top">
                      <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" name="example-checkbox10" value="option10">
                        <span class="custom-control-label">Settings Changings...</span>
                      </label>
                      <span class="ms-auto">
                        <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                        <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                      </span>
                    </div><div class="d-flex p-3 border-top">
                      <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" name="example-checkbox9" value="option9">
                        <span class="custom-control-label">System updated</span>
                      </label>
                      <span class="ms-auto">
                        <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                        <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                      </span>
                    </div>
                    <div class="d-flex p-3 border-top border-bottom">
                      <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" name="example-checkbox10" value="option10">
                        <span class="custom-control-label">Settings Changings...</span>
                      </label>
                      <span class="ms-auto">
                        <i class="si si-pencil text-primary me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Edit" data-bs-original-title=""></i>
                        <i class="si si-trash text-danger me-2" data-bs-toggle="tooltip" title="" data-bs-placement="top" data-original-title="Delete" data-bs-original-title=""></i>
                      </span>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
        </div>
      </div>
        <div [ngbNavOutlet]="nav"></div>

    </div>
  </ng-scrollbar>
</div>
<!--/Sidebar-right-->

<ng-template #content let-modal>
  <div class="chat chatbox" id="chatmodel">
    <div class="card overflow-hidden mb-0 border-0">
      <!-- action-header -->
      <div class="action-header clearfix">
        <div class="float-start hidden-xs d-flex ms-2">
          <div class="img_cont me-3">
            <img src="./assets/img/faces/6.jpg" class="rounded-circle user_img" alt="img">
          </div>
          <div class="align-items-center mt-0">
            <h4 class="text-white mb-0 font-weight-semibold">Daneil Scott</h4>
            <span class="dot-label bg-success"></span><span class="me-3 text-white">online</span>
          </div>
        </div>
        <ul class="ah-actions actions align-items-center">
          <li class="call-icon">
            <a href="javascript:;" class="d-done d-md-block phone-button" (click)="open(audio)">
              <i class="fe fe-phone"></i>
            </a>
          </li>
          <li class="video-icon">
            <a href="javascript:;" class="d-done d-md-block phone-button" (click)="open(video)">
              <i class="fe fe-video"></i>
            </a>
          </li>
          <li ngbDropdown>
            <a ngbDropdownToggle aria-expanded="true">
              <i class="fe fe-more-vertical"></i>
            </a>
            <ul ngbDropdownMenu class="dropdown-menu-right">
              <li><i class="fa fa-user-circle"></i> View profile</li>
              <li><i class="fa fa-users"></i>Add friends</li>
              <li><i class="fa fa-plus"></i> Add to group</li>
              <li><i class="fa fa-ban"></i> Block</li>
            </ul>
          </li>
          <li>
            <a (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true"><i class="fe fe-x-circle text-white"></i></span>
            </a>
          </li>
        </ul>
      </div>
      <!-- action-header end -->

      <!-- msg_card_body -->
      <div class="card-body msg_card_body">
        <div class="chat-box-single-line">
          <abbr class="timestamp">july 1st, 2021</abbr>
        </div>
        <div class="d-flex justify-content-start">
          <div class="img_cont_msg">
            <img src="./assets/img/faces/6.jpg" class="rounded-circle user_img_msg" alt="img">
          </div>
          <div class="msg_cotainer">
            Hi, how are you Jenna Side?
            <span class="msg_time">8:40 AM, Today</span>
          </div>
        </div>
        <div class="d-flex justify-content-end ">
          <div class="msg_cotainer_send">
            Hi Connor Paige i am good tnx how about you?
            <span class="msg_time_send">8:55 AM, Today</span>
          </div>
          <div class="img_cont_msg">
            <img src="./assets/img/faces/9.jpg" class="rounded-circle user_img_msg" alt="img">
          </div>
        </div>
        <div class="d-flex justify-content-start ">
          <div class="img_cont_msg">
            <img src="./assets/img/faces/6.jpg" class="rounded-circle user_img_msg" alt="img">
          </div>
          <div class="msg_cotainer">
            I am good too, thank you for your chat template
            <span class="msg_time">9:00 AM, Today</span>
          </div>
        </div>
        <div class="d-flex justify-content-end ">
          <div class="msg_cotainer_send">
            You welcome Connor Paige
            <span class="msg_time_send">9:05 AM, Today</span>
          </div>
          <div class="img_cont_msg">
            <img src="./assets/img/faces/9.jpg" class="rounded-circle user_img_msg" alt="img">
          </div>
        </div>
        <div class="d-flex justify-content-start ">
          <div class="img_cont_msg">
            <img src="./assets/img/faces/6.jpg" class="rounded-circle user_img_msg" alt="img">
          </div>
          <div class="msg_cotainer">
            Yo, Can you update Views?
            <span class="msg_time">9:07 AM, Today</span>
          </div>
        </div>
        <div class="d-flex justify-content-end mb-4">
          <div class="msg_cotainer_send">
            But I must explain to you how all this mistaken born and I will give
            <span class="msg_time_send">9:10 AM, Today</span>
          </div>
          <div class="img_cont_msg">
            <img src="./assets/img/faces/9.jpg" class="rounded-circle user_img_msg" alt="img">
          </div>
        </div>
        <div class="d-flex justify-content-start ">
          <div class="img_cont_msg">
            <img src="./assets/img/faces/6.jpg" class="rounded-circle user_img_msg" alt="img">
          </div>
          <div class="msg_cotainer">
            Yo, Can you update Views?
            <span class="msg_time">9:07 AM, Today</span>
          </div>
        </div>
        <div class="d-flex justify-content-end mb-4">
          <div class="msg_cotainer_send">
            But I must explain to you how all this mistaken born and I will give
            <span class="msg_time_send">9:10 AM, Today</span>
          </div>
          <div class="img_cont_msg">
            <img src="./assets/img/faces/9.jpg" class="rounded-circle user_img_msg" alt="img">
          </div>
        </div>
        <div class="d-flex justify-content-start ">
          <div class="img_cont_msg">
            <img src="./assets/img/faces/6.jpg" class="rounded-circle user_img_msg" alt="img">
          </div>
          <div class="msg_cotainer">
            Yo, Can you update Views?
            <span class="msg_time">9:07 AM, Today</span>
          </div>
        </div>
        <div class="d-flex justify-content-end mb-4">
          <div class="msg_cotainer_send">
            But I must explain to you how all this mistaken born and I will give
            <span class="msg_time_send">9:10 AM, Today</span>
          </div>
          <div class="img_cont_msg">
            <img src="./assets/img/faces/9.jpg" class="rounded-circle user_img_msg" alt="img">
          </div>
        </div>
        <div class="d-flex justify-content-start">
          <div class="img_cont_msg">
            <img src="./assets/img/faces/6.jpg" class="rounded-circle user_img_msg" alt="img">
          </div>
          <div class="msg_cotainer">
            Okay Bye, text you later..
            <span class="msg_time">9:12 AM, Today</span>
          </div>
        </div>
      </div>
      <!-- msg_card_body end -->
      <!-- card-footer -->
      <div class="card-footer">
        <div class="msb-reply d-flex">
          <div class="input-group">
            <input type="text" class="form-control " placeholder="Typing....">
            <div class="input-group-append ">
              <button type="button" class="btn btn-primary ">
                <i class="far fa-paper-plane" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div><!-- card-footer end -->
    </div>
  </div>
</ng-template>
<ng-template #video let-modal>
  <div class="modal-body mx-auto text-center p-7">
    <h5>Nowa Video call</h5>
    <img src="./assets/img/faces/6.jpg" class="rounded-circle user-img-circle h-8 w-8 mt-4 mb-3" alt="img">
    <h4 class="mb-1 font-weight-semibold">Daneil Scott</h4>
    <h6>Calling...</h6>
    <div class="mt-5">
      <div class="row">
        <div class="col-4">
          <a class="icon icon-shape rounded-circle mb-0 me-3" href="javascript:void(0);"
            (click)="modal.dismiss('Cross click')">
            <i class="fas fa-video-slash"></i>
          </a>
        </div>
        <div class="col-4">
          <a class="icon icon-shape rounded-circle text-white mb-0 me-3" href="javascript:void(0);"
            (click)="modal.dismiss('Cross click')">
            <i class="fas fa-phone bg-danger text-white"></i>
          </a>
        </div>
        <div class="col-4">
          <a class="icon icon-shape rounded-circle mb-0 me-3" href="javascript:void(0);"
            (click)="modal.dismiss('Cross click')">
            <i class="fas fa-microphone-slash"></i>
          </a>
        </div>
      </div>
    </div>
  </div><!-- modal-body -->
</ng-template>
<ng-template #audio let-modal>
  <div class="modal-body mx-auto text-center p-7">
    <h5>Nowa Voice call</h5>
    <img src="./assets/img/faces/6.jpg" class="rounded-circle user-img-circle h-8 w-8 mt-4 mb-3" alt="img">
    <h4 class="mb-1  font-weight-semibold">Daneil Scott</h4>
    <h6>Calling...</h6>
    <div class="mt-5">
      <div class="row">
        <div class="col-4">
          <a class="icon icon-shape rounded-circle mb-0 me-3" href="javascript:void(0);"
            (click)="modal.dismiss('Cross click')">
            <i class="fas fa-volume-up bg-light"></i>
          </a>
        </div>
        <div class="col-4">
          <a class="icon icon-shape rounded-circle text-white mb-0 me-3" href="javascript:void(0);"
            (click)="modal.dismiss('Cross click')">
            <i class="fas fa-phone text-white bg-primary"></i>
          </a>
        </div>
        <div class="col-4">
          <a class="icon icon-shape  rounded-circle mb-0 me-3" href="javascript:void(0);"
            (click)="modal.dismiss('Cross click')">
            <i class="fas fa-microphone-slash bg-light"></i>
          </a>
        </div>
      </div>
    </div>
  </div><!-- modal-body -->
</ng-template>
