<!-- CONTAINER OPEN -->

<div class="d-flex justify-content-center align-items-center min-vh-100">
  <!-- <div class="login-img"> -->
  <!-- <div class="col col-login mx-auto">
    <div class="text-center">
      <a routerLink="/dashboard/vendas"><img src="../../../assets/" style="height: 120px; width: 80px;"
          class="header-brand-img m-0" alt=""></a>

    </div>
  </div> -->
  <div class="container-login100" *ngIf="step1">
    <div class="wrap-login100 p-6">
      <div class="col col-login mx-auto">
        <form [formGroup]="form" class="card" method="post">
          <div class="card-body p-6">
            <h3 class="text-center card-title">Esqueceu a senha</h3>
            <div class="wrap-input100 validate-input" data-validate="Email valido é requirido">
              <input class="input100" type="text" name="email" formControlName="email" placeholder="Informe seu E-mail">
              <span class="focus-input100"></span>
              <span class="symbol-input100">
                <i class="zmdi zmdi-email" aria-hidden="true"></i>
              </span>
            </div>
            <div class="form-footer">
              <a role="button" (click)="sendEmail()" class="btn btn-primary btn-block">Enviar</a>
            </div>
            <div class="text-center text-muted mt-3 ">
              Caso não tenha, <a routerLink="/auth/login">volte para o login</a>.
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="container-login100" *ngIf="step2">
    <div class="wrap-login100 p-6">
      <div class="col col-login mx-auto">
        <form [formGroup]="form" class="card" method="post">
          <div class="card-body p-6">
            <h3 class="text-center card-title">Esqueceu a senha</h3>
            <h6 class="font-weight-500 text-muted text-center">Codigo de acesso foi enviado no seu e-mail
            </h6>
            <div class="wrap-input100 validate-input" data-validate="Código é requirido">
              <input class="input100" type="tel" id="codigo" name="code" formControlName="code" placeholder="Codigo">
              <span class="focus-input100"></span>
              <span class="symbol-input100">
                <i class="zmdi zmdi-key" aria-hidden="true"></i>
              </span>
            </div>
            <div class="form-footer">
              <a role="button" (click)="sendCode()" class="btn btn-primary btn-block">Enviar</a>
            </div>
            <div class="d-flex flex-column mt-4">
              <span class="text-muted text-center">Não recebeu seu código?</span>
              <span *ngIf="resendCode > 0" class="text-dark text-center">Reenviar código em 00:<span
                  *ngIf="resendCode < 10">0</span>{{resendCode}}</span>
              <a *ngIf="resendCode == 0" class="text-center text-dark">Reenviar código</a>
            </div>
            <div class="text-center text-muted mt-3 ">
              Caso não tenha, <a routerLink="/auth/login">volte para o login</a>.
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="container-login100" *ngIf="step3">
    <div class="wrap-login100 p-6">
      <div class="col col-login mx-auto">
        <form [formGroup]="form" class="card" method="post">
          <div class="card-body p-6">
            <h3 class="text-center card-title">Esqueceu a senha</h3>
            <h6 class="font-weight-500 text-muted text-center">Digite sua nova senha</h6>
            <div class="wrap-input100 validate-input" data-validate="Senha é requirido">
              <input class="input100" type="password" formControlName="password" id="password" name="password"
                placeholder="Nova Senha">
              <span class="focus-input100"></span>
              <span class="symbol-input100">
                <i class="zmdi zmdi-lock" aria-hidden="true"></i>
              </span>
            </div>
            <div class="wrap-input100 validate-input" data-validate="Confirmar senha é Obrigatório">
              <input class="input100" type="password" formControlName="passwordConfirm" id="passwordConfirm" name="passwordConfirm"
                placeholder="Confirmar Nova Senha">
              <span class="focus-input100"></span>
              <span class="symbol-input100">
                <i class="zmdi zmdi-lock" aria-hidden="true"></i>
              </span>
            </div>
            <div class="form-footer">
              <a role="button" (click)="forgotPassword()" class="btn btn-primary btn-block">Confirmar</a>
            </div>
            <div class="text-center text-muted mt-3 ">
              Caso não tenha, <a routerLink="/auth/login">volte para o login</a>.
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>
<!-- CONTAINER CLOSED -->