<div class="error-page1">
  <!-- Page -->
  <div class="page">

    <router-outlet>
      <app-loader></app-loader>
    </router-outlet>

  </div>
  <!-- End Page -->
</div>
