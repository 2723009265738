import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base.service";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { UserBackofficeRegisterRequestDto } from "../dtos/user/user-backoffice-register-request.dto";
import { map, catchError, Observable } from "rxjs";
import { UserBackofficeResetPasswordRequestDto } from "../dtos/authentication/user-backoffice-reset-password-request.dto";
import { UserBackofficeResetPasswordConfirmationRequestDto } from "../dtos/authentication/user-backoffice-reset-password-request.dto copy";
import { UserRegisterPasswordRequestDto } from "../dtos/user/user-register-password-request.dto";

@Injectable({
  providedIn: "root",
})
export abstract class UserService extends BaseService {
  url: string = `${environment.apiKey}/user`;

  constructor(private readonly httpClient: HttpClient) {
    super();
  }

  getUser() {
    return this.httpClient
      .get(`${this.url}/user`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  registerUser(dto: UserBackofficeRegisterRequestDto) {
    return this.httpClient
      .post(`${this.url}/register`, dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  updatePassword(dto: any) {
    return this.httpClient
      .post(`${this.url}/update-password`, dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  listUsers(): Observable<any> {
    return this.httpClient
      .get(`${this.url}/list`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  verifyUser(_id: string): Observable<any> {
    return this.httpClient
      .get(`${this.url}/verify-user/id/${_id}`, this.anonymousHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  registerPassword(dto: UserRegisterPasswordRequestDto) {
    return this.httpClient
      .put(`${this.url}/register-password/${dto._id}`, dto, this.anonymousHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  updateUser(_id: string, dto: UserBackofficeRegisterRequestDto) {
    return this.httpClient
      .put(`${this.url}/edit/${_id}`, dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  delete(_id: string) {
    return this.httpClient
      .delete(`${this.url}/delete/${_id}`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  resetPassword(dto: UserBackofficeResetPasswordRequestDto) {
    return this.httpClient
      .post(`${this.url}/reset-password-verification`, dto, this.anonymousHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  resetPasswordConfirmation(dto: UserBackofficeResetPasswordConfirmationRequestDto) {
    return this.httpClient
      .patch(`${this.url}/reset-password-confirmation`, dto, this.anonymousHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  resendEmail(id: string) {
    return this.httpClient
      .post(`${this.url}/resend-email/${id}`, {}, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
}
