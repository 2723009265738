<div class="app-header header top-header ">
  <div class="container-fluid main-container">
    <div class="d-flex">

      <div class="dropdown side-nav">
        <a aria-label="Hide Sidebar" class="app-sidebar__toggle" data-bs-toggle="sidebar" (click)="toggleSidebar()">

          <a class="open-toggle" href="javascript:void(0)">
            <i class="bx bx-menu-alt-left header-icon"></i>
          </a>
          <a class="close-toggle" href="javascript:void(0)">
            <i class="bx bx-x header-icon"></i>
          </a>
        </a>
      </div>
      <a class="header-brand" routerLink="/dashboard/vendas">
        <img src="../../../../assets/logo.png" class="header-brand-img desktop-lgo" alt="Rifa">
        <img src="../../../../assets/logo.png" class="header-brand-img dark-logo" alt="Rifa">
        <img src=".../../../../assets/logo.png" class="header-brand-img mobile-logo" alt="Rifa">
        <img src="../../../../assets/logo.png" class="header-brand-img darkmobile-logo" alt="Rifa">
      </a>


      <div class="mt-2 ms-md-4">
      </div>
      <div class="d-flex order-lg-2 ms-lg-auto">
        <button class="navbar-toggler navresponsive-toggler d-lg-none ms-auto" type="button" (click)="collapse.toggle()"
          [attr.aria-expanded]="!isCollapsed">
          <span class="navbar-toggler-icon fe fe-more-vertical"></span>
        </button>
        <div class="navbar navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0">
          <div class="collapse navbar-collapse" id="navbarSupportedContent-4" #collapse="ngbCollapse"
            [(ngbCollapse)]="isCollapsed">
            <div class="d-flex order-lg-2">
              <div ngbDropdown class="dropdown profile-dropdown">
                <a ngbDropdownToggle href="javascript:void(0)" class="nav-link icon px-1" data-bs-toggle="dropdown">
                  <img src="./assets/images/users/female/1.jpg" alt="img" class="avatar brround">
                </a>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu-arrow animated">
                  <div class=" dropdown-header noti-title text-center border-bottom p-3">
                    <div ngbDropdownItem class="header-usertext">
                      <h5 class="mb-1">{{ user?.name || seller?.name }}</h5>
                    </div>
                  </div>
                  <a ngbDropdownItem class="dropdown-item" routerLink="/pages/profile">
                    <i class="fe fe-user me-2"></i> <span>Meu perfil</span>
                  </a>
                  <a ngbDropdownItem class="dropdown-item" href="/">
                    <i class="fe fe-log-out me-2"></i> <span>Sair</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>