<div class="app-header header top-header" >
  <div class="container-fluid main-container">
      <div class="d-flex">

          <div class="dropdown side-nav">
               <a
              aria-label="Hide Sidebar" class="app-sidebar__toggle" data-bs-toggle="sidebar"   (click)="toggleSidebar()">

                  <a class="open-toggle" href="javascript:void(0)">
                      <i class="bx bx-menu-alt-left header-icon"></i>
                  </a>
                  <a class="close-toggle" href="javascript:void(0)">
                      <i class="bx bx-x header-icon"></i>
                  </a>
              </a>
          </div>
          <a class="header-brand" routerLink="/dashboard/sales">
              <img src="./assets/images/brand/logo.png" class="header-brand-img desktop-lgo" alt="Slica logo">
              <img src="./assets/images/brand/logo1.png" class="header-brand-img dark-logo" alt="Slica logo">
              <img src="./assets/images/brand/icon.png" class="header-brand-img mobile-logo" alt="Slica logo">
              <img src="./assets/images/brand/icon1.png" class="header-brand-img darkmobile-logo" alt="Slica logo">
          </a>


          <div class="mt-1 ms-md-4">
              <form class="form-inline">
                  <div class="search-element">
                      <input type="search" class="form-control header-search" placeholder="Search…" aria-label="Search" tabindex="1"
                      [(ngModel)]="text"
        [ngModelOptions]="{standalone: true}" (keyup)="Search(text)" autocomplete="off"/>
                      <button class="btn btn-primary-color" type="button">
                          <i class="bx bx-search header-icon search-icon"></i>
                      </button>
                      <div class="search-result p-absolute w-100 card border mt-1" *ngIf="menuItems?.length; else elseBlock">
                          <div class="card-header">
                            <h4 class="card-title me-2">Search result of "{{text}}" </h4> <small></small>
                          </div>
                          <ul class="list-group text-break">
                            <li *ngFor="let menuItem of menuItems | slice:0:5" [routerLink]="menuItem?.path"
                              class="list-group-item header-list-group border-start-end-top-0 br-0" (click)="clearSearch()">
                              <i class="fa fa-angle-double-right me-2" aria-hidden="true"></i>{{menuItem?.title}}
                            </li>
                          </ul>
                        </div>
                        <ng-template #elseBlock>
                          <ul class="list-group p-absolute w-100 mt-1 serach-result-empty text-break">
                            <li class="list-group-item no-result" [ngClass]="{'show':SearchResultEmpty}"><i
                                class="fa fa-frown-o text-primary me-2" aria-hidden="true"></i>No Search Result Found with "{{text}}"
                            </li>
                          </ul>
                        </ng-template>
                  </div>
              </form>
          </div>
          <div class="d-flex order-lg-2 ms-lg-auto">
              <button class="navbar-toggler navresponsive-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4" aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon fe fe-more-vertical header-icon"></span>
              </button>
              <div class="navbar navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0">
                  <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
                      <div class="d-flex">
                          <a href="javascript:void(0)" data-bs-toggle="search" class="nav-link nav-link-lg d-lg-none navsearch">
                              <i class="bx bx-search header-icon search-icon"></i>
                          </a>
                          <div class="dropdown header-theme">
                              <a class="nav-link icon layout-setting" appToggleTheme>
                                  <span class="dark-layout">
                                      <i class="bx bx-sun header-icon"></i>
                                  </span>
                                  <span class="light-layout">
                                      <i class="bx bx-moon header-icon"></i>
                                  </span>
                              </a>
                          </div>
                          <div class="dropdown header-fullscreen">
                              <a class="nav-link icon full-screen-link" id="fullscreen-button" appFullscreen>
                                  <i class="bx bx-fullscreen header-icon"></i>
                              </a>

                          </div>


                          <div ngbDropdown class="dropdown d-flex header-notify ">
                              <a class="nav-link icon" data-bs-toggle="dropdown"  ngbDropdownToggle>
                                  <i class="bx bx-bell header-icon"></i>
                                  <span class="pulse "></span>
                              </a>
                              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu-arrow animated ">

                                  <div class="notifications-scroll1 ">
                                    <ng-scrollbar>
                                      <a href="/pages/mailinbox" ngbDropdownItem class="dropdown-item mt-2 d-flex pb-3">
                                          <div class="notifyimg bg-info">
                                              <i class="fe fe-thumbs-up"></i>
                                          </div>
                                          <div class="header-notify-msg wd-80p">
                                              <h6 class="mb-1">Someone likes our posts.</h6>
                                              <div class="small text-muted">3 hours ago</div>
                                          </div>
                                      </a>
                                      <a href="/pages/mailinbox" ngbDropdownItem class="dropdown-item d-flex pb-3">
                                          <div class="notifyimg bg-warning">
                                              <i class="fe fe-message-square"></i>
                                          </div>
                                          <div class="header-notify-msg">
                                              <h6 class="mb-1"> 3 New Comments</h6>
                                              <div class="small text-muted">5 hours ago</div>
                                          </div>
                                      </a>
                                      <a href="/pages/mailinbox" ngbDropdownItem class="dropdown-item d-flex pb-3">
                                          <div class="notifyimg bg-primary">
                                              <i class="fe fe-settings"></i>
                                          </div>
                                          <div class="header-notify-msg">
                                              <h6 class="mb-1"> Server Rebooted.</h6>
                                              <div class="small text-muted">5 hours ago</div>
                                          </div>
                                      </a>
                                      <a href="/pages/mailinbox" ngbDropdownItem class="dropdown-item d-flex pb-3">
                                          <div class="notifyimg bg-danger">
                                              <i class="fe fe-shopping-bag"></i>
                                          </div>
                                          <div class="header-notify-msg">
                                              <h6 class="mb-1"> New Orders Placed.</h6>
                                              <div class="small text-muted">45 mins ago</div>
                                          </div>
                                      </a>
                                      <a href="/pages/mailinbox" ngbDropdownItem class="dropdown-item d-flex pb-3">
                                          <div class="notifyimg bg-secondary">
                                              <i class="fe fe-activity"></i>
                                          </div>
                                          <div class="header-notify-msg">
                                              <h6 class="mb-1"> Orders Activity.</h6>
                                              <div class="small text-muted">30 mins ago</div>
                                          </div>
                                      </a>
                                      <a href="/pages/mailinbox" ngbDropdownItem class="dropdown-item d-flex pb-3">
                                          <div class="notifyimg bg-cyan">
                                              <i class="fe fe-shopping-cart"></i>
                                          </div>
                                          <div class="header-notify-msg">
                                              <h6 class="mb-1"> Cart Items are added.</h6>
                                              <div class="small text-muted">5 mins ago</div>
                                          </div>
                                      </a>
                                    </ng-scrollbar>
                                  </div>

                                  <div class="text-center p-2 border-top">
                                      <a href="/pages/mailinbox"  class="">View All Notifications</a>
                                  </div>
                              </div>
                          </div>

                          <div ngbDropdown class="dropdown header-notify">
                              <a class="nav-link icon " data-bs-toggle="dropdown" ngbDropdownToggle >
                                  <i class="bx bx-envelope header-icon"></i>
                                  <span class=" nav-unread badge badge-warning badge-pill">6</span>
                              </a>

                              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu-arrow ">

                                  <div class="notifications-scroll2 ">
                                      <ng-scrollbar>
                                        <a routerLink="/apps/chat"  ngbDropdownItem class="dropdown-item d-flex mt-2 pb-3 ">
                                            <div class="avatar avatar-md brround me-3 d-block cover-image" data-image-src="./assets/images/users/male/4.jpg" style="background: url(./assets/images/users/male/4.jpg) center center;">
                                                <span class="avatar-status bg-green"></span>
                                            </div>

                                            <div class="header-notify-msg">
                                                <h6 class="mb-1">Lucas Walsh</h6>
                                                <p class="mb-0 fs-13 ">Hey! there I' am available</p>
                                                <div class="small text-muted">3 hours ago</div>
                                            </div>
                                        </a>
                                        <a routerLink="/apps/chat" ngbDropdownItem class="dropdown-item d-flex pb-3">
                                            <div class="avatar avatar-md brround me-3 d-block cover-image" data-image-src="./assets/images/users/female/1.jpg" style="background: url(./assets/images/users/female/1.jpg) center center;">
                                                <span class="avatar-status bg-red"></span>
                                            </div>
                                            <div>
                                                <h6 class="mb-1">Rebecca Short</h6>
                                                <p class="mb-0 fs-13 ">New product Launching</p>
                                                <div class="small text-muted">5 hours ago</div>
                                            </div>
                                        </a>
                                        <a href="/apps/chat" ngbDropdownItem class="dropdown-item d-flex pb-3">
                                            <div class="avatar avatar-md brround me-3 d-block cover-image" data-image-src="./assets/images/users/male/1.jpg" style="background: url(./assets/images/users/female/1.jpg) center center;">
                                                <span class="avatar-status bg-gray"></span>
                                            </div>
                                            <div>
                                                <h6 class="mb-1">Dennis Viol</h6>
                                                <p class="mb-0 fs-13 ">Products page updated.</p>
                                                <div class="small text-muted">5 hours ago</div>
                                            </div>
                                        </a>
                                        <a href="/apps/chat" ngbDropdownItem  class="dropdown-item d-flex pb-3">
                                            <div class="avatar avatar-md brround me-3 d-block cover-image" data-image-src="./assets/images/users/female/7.jpg" style="background: url(./assets/images/users/female/1.jpg) center center;">
                                                <span class="avatar-status bg-success"></span>
                                            </div>
                                            <div>
                                                <h6 class="mb-1">Enna Sheru</h6>
                                                <p class="mb-0 fs-13 ">New product Launching</p>
                                                <div class="small text-muted">5 hours ago</div>
                                            </div>
                                        </a>
                                        <a href="/apps/chat" ngbDropdownItem class="dropdown-item d-flex pb-3">
                                            <div class="avatar avatar-md brround me-3 d-block cover-image" data-image-src="./assets/images/users/male/4.jpg" style="background: url(./assets/images/users/female/1.jpg) center center;">
                                                <span class="avatar-status bg-red"></span>
                                            </div>
                                            <div>
                                                <h6 class="mb-1">Nicholas Angel</h6>
                                                <p class="mb-0 fs-13 ">2 New Products added</p>
                                                <div class="small text-muted">5 hours ago</div>
                                            </div>
                                        </a>
                                        <a href="/apps/chat" ngbDropdownItem class="dropdown-item d-flex pb-3">
                                            <div class="avatar avatar-md brround me-3 d-block cover-image" data-image-src="./assets/images/users/female/8.jpg" style="background: url(./assets/images/users/female/8.jpg) center center;">
                                                <span class="avatar-status bg-yellow"></span>
                                            </div>
                                            <div>
                                                <h6 class="mb-1">Nicola Vance</h6>
                                                <p class="mb-0 fs-13">New Schedule Realease</p>
                                                <div class="small text-muted">45 mintues ago</div>
                                            </div>
                                        </a>
                                      </ng-scrollbar>
                                  </div>

                                  <div ngbDropdownItem  class="text-center p-2 border-top">
                                      <a routerLink ="/apps/chat"  class="">View All Notifications</a>
                                  </div>

                                </div>

                          </div>

                          <div ngbDropdown class="dropdown profile-dropdown">
                              <a  ngbDropdownToggle href="javascript:void(0)" class="nav-link icon px-1" data-bs-toggle="dropdown">
                                  <img src="./assets/images/users/female/1.jpg" alt="img" class="avatar brround">
                              </a>
                              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu-arrow animated">
                                  <div class=" dropdown-header noti-title text-center border-bottom p-3">
                                      <div ngbDropdownItem  class="header-usertext">
                                          <h5 class="mb-1">Jacob Allan</h5>
                                          <p class="mb-0">Web Developer</p>
                                      </div>
                                  </div>
                                  <a ngbDropdownItem  class="dropdown-item" routerLink="/pages/profile">
                                      <i class="fe fe-user me-2"></i> <span>My profile</span>
                                  </a>
                                  <a ngbDropdownItem  class="dropdown-item" routerLink="/pages/editprofile">
                                      <i class="fe fe-settings me-2"></i> <span>Settings</span>
                                  </a>
                                  <a ngbDropdownItem  class="dropdown-item" routerLink="/pages/services">
                                      <i class="fe fe-calendar me-2"></i> <span>Activity</span>
                                  </a>
                                  <a ngbDropdownItem  class="dropdown-item" href="/pages/services">
                                      <i class="fe fe-compass me-2"></i> <span>Support</span>
                                  </a>
                                  <a ngbDropdownItem  class="dropdown-item" href="/custompages/login">
                                      <i class="fe fe-log-out me-2"></i> <span>Logout</span>
                                  </a>
                              </div>
                          </div>
                          <div class="dropdown header-sidebar">
                              <a (click)="toggleSidebarNotification()" class="nav-link icon sidebar-right-mobile" data-bs-toggle="sidebar-right" data-bs-target=".sidebar-right">
                                  <i class="bx bx-menu-alt-right header-icon"></i>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
      </div>
  </div>
</div>
