<div class="horizontalMenucontainer">


  <app-switcher></app-switcher>
    <div class="page">
      <div class="page-main">
        <!-- // header -->
        <app-header></app-header>
        <!-- //sidebar -->
        <app-sidebar appHoverEffectSidebar></app-sidebar>
        <div class="jumps-prevent" style="padding-top: 74px;"></div>
        <div class="app-content main-content">
          <!-- <app-loader></app-loader> -->
           <div class="side-app"[ngClass]="{ 'pt-60': scrolled }">
            <div class="main-container container-fluid" (click)="toggleSwitcherBody()">

              <!-- // contetnt  -->
              <router-outlet> <app-loader></app-loader></router-outlet>
          </div>
        </div>
      </div>
      <app-right-sidebar></app-right-sidebar>
      <app-footer></app-footer>
    </div>
    </div>
  <app-tab-to-top></app-tab-to-top>

</div>
