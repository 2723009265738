import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, catchError, map } from "rxjs";
import { UserRegisterPasswordRequestDto } from "../dtos/user/user-register-password-request.dto";
import { SellerUpdateRequestDto } from "../dtos/seller/seller-update-request.dto";
import { SellerUpdatePasswordDto } from "../dtos/seller/seller-update-password.dto";

@Injectable()
export class SellerService extends BaseService {

  url: string = `${environment.apiKey}/seller`;

  constructor(
    private httpClient: HttpClient,
  ) {
    super();
  }

  registerUser(dto: any): Observable<any> {
    return this.httpClient
      .post(`${this.url}/register`, this.encrypt(dto), this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  listAll(): Observable<any> {
    return this.httpClient
      .get(`${this.url}`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  resendEmail(_id: string): Observable<any> {
    return this.httpClient
      .post(`${this.url}/resend-email/${_id}`, null, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getById(id: string): Observable<any> {
    return this.httpClient
      .get(`${this.url}/get-by-id/${id}`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getSellerAuth(): Observable<any> {
    return this.httpClient
      .get(`${this.url}/get-by-auth`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  registerPassword(dto: UserRegisterPasswordRequestDto) {
    return this.httpClient
      .put(`${this.url}/register-password/${dto._id}`, dto, this.anonymousHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  verifySeller(_id: string): Observable<any> {
    return this.httpClient
      .get(`${this.url}/verify-seller/id/${_id}`, this.anonymousHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }


  editUserById(id: string, dto: SellerUpdateRequestDto): Observable<any> {
    return this.httpClient
      .put(`${this.url}/edit-by-id/${id}`, dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  inactiveOrActive(id: string): Observable<any> {
    return this.httpClient
      .put(`${this.url}/active-or-inactive/${id}`, null, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  deleteById(id: string): Observable<any> {
    return this.httpClient
      .delete(`${this.url}/delete-by-id/${id}`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  updatePassword(id:string, dto:SellerUpdatePasswordDto){
    return this.httpClient
      .put(`${this.url}/update-password/${id}`, dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError))
  }

}
