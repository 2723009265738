export const environment = {
  production: false,
  apiKey: 'https://dev.painel.api.elev-c.com',
  encryptKey: '123456',
  firebase: {
    apiKey: "AIzaSyD1MXaYxNQDL7YiXq0LpQoqhW55G4f6Kz4",
    authDomain: "hml-elev-c.firebaseapp.com",
    projectId: "hml-elev-c",
    storageBucket: "hml-elev-c.appspot.com",
    messagingSenderId: "183256481562",
    appId: "1:183256481562:web:85b59e53bd00d5013536a3",
    measurementId: "G-2PQGSKD5WS"
  }
};