<div class="app-sidebar__overlay" (click)="sidebarClose()"></div>

<!--APP-SIDEBAR-->
<!-- <div class="app-sidebar__overlay" (click)="sidebarClose()"></div> -->
<!-- Sidemenu -->

<div class="app-sidebar sticky" [ngClass]="{ 'fixed-header visible-title': scrolled }">

  <div class="main-sidebar-header active">
    <a class="desktop-logo logo-light active" routerLink="/dashboard">
      <img src="../../../../assets/logo.png" style="height: 20px; width: 35px;" class="main-logo mt-2" alt="Logo" />
    </a>
    <a class="desktop-logo logo-dark active " routerLink="/dashboard">
      <img src="../../../../assets/logo.png" style="height: 20px; width: 35px;" class=" main-logo" alt="Logo" />
    </a>
    <a class="logo-icon mobile-logo icon-light active mt-2" routerLink="/dashboard">
      <img src="../../../../assets/logo.png" style="height: 20px; width: 35px;" alt="logo" />
    </a>
    <a class="logo-icon mobile-logo icon-dark active" routerLink="/dashboard">
      <img src="../../../../assets/logo.png" style="height: 20px; width: 35px;" alt="logo" />
    </a>

  </div>
  <ng-scrollbar>
    <div class="main-sidemenu">
      <!-- left-arrow -->
      <div class="slide-left disabled" id="slide-left"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24"
          height="24" viewBox="0 0 24 24">
          <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
        </svg></div>
      <!-- <div class="slide-leftRTL " id="slide-leftRTL"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191"
        width="24" height="24" viewBox="0 0 24 24">
        <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" /></svg></div> -->
      <ul class="side-menu">
        <!-- 1st Level Menu -->
        <li *ngFor="let menuItem of menuItems"
          [ngClass]="{'':menuItem.headTitle,'slide':menuItem.title, 'active': menuItem.active, 'is-expanded': menuItem.active}">
          <h3 *ngIf="menuItem.headTitle">{{menuItem.headTitle}}</h3>

          <!-- has-Link -->
          <a class="side-menu__item has__link" [routerLink]="!menuItem.type ? null : [menuItem.path]"
            *ngIf="menuItem.type === 'link' " routerLinkActive="active" (click)="toggleNavActive(menuItem)">
            <span class="shape1"></span>
            <span class="shape2"></span>
            <span class="side-menu__icon">
              <i class="fe fe-{{menuItem.icon}} "></i>
            </span>
            <span class="side-menu__label">{{menuItem.title}}</span>
          </a>
          <!-- empty path -->
          <a class="side-menu__item has__link" href="javascript:;" *ngIf="menuItem.type === 'empty'"
            (click)="toggleNavActive(menuItem)">
            <span class="shape1"></span>
            <span class="shape2"></span>
            <span class="side-menu__icon">
              <i class="fe fe-{{menuItem.icon}} "></i>
            </span>
            <span class="side-menu__label">{{menuItem.title}}</span>
          </a>
          <!-- external -->
          <a class="side-menu__item has__link" routerLinkActive="active" target="_blank"
            [routerLink]="!menuItem.type ? null : [menuItem.path] " *ngIf="menuItem.type === 'external' "
            (click)="toggleNavActive(menuItem)">
            <span class="side-menu__icon">
              <i class="fe fe-{{menuItem.icon}} "></i>
            </span>
            <span class="side-menu__label">{{menuItem.title}}</span>
            <span class="badge badge-sm bg-{{menuItem.badgeClass}} badge-hide">{{menuItem.badgeValue}}</span>
          </a>
          <!-- has-Sub -->
          <a class="side-menu__item" [routerLink]="menuItem.type ? null: [menuItem.path]"
            *ngIf="menuItem.type === 'sub'" (click)="toggleNavActive(menuItem)" [ngClass]="{'active': menuItem.active}">
            <span class="shape1"></span>
            <span class="shape2"></span>
            <span class="side-menu__icon">
              <i class="fe fe-{{menuItem.icon}} "></i>
            </span>
            <span class="side-menu__label">{{menuItem.title}}</span>
            <span class="{{menuItem.badgeClass}}" *ngIf="menuItem.badgeClass && menuItem.badgeValue">
              {{menuItem.badgeValue}}
            </span>
            <i class="angle fe fe-chevron-right" [ngClass]="{ 'd-none': menuItem.badgeClass}"></i>

          </a>
          <!-- 2nd Level menu -->
          <ul class="slide-menu" *ngIf="menuItem.children" [ngClass]="{'open': menuItem.active}">
            <li *ngFor="let childrenItem of menuItem.children"
              [ngClass]="{'is-expanded active': childrenItem.active, 'sub-slide': childrenItem.Menusub}">

              <!-- link -->
              <a class="slide-item" routerLinkActive="active"
                [routerLink]="!childrenItem.type ? null : [childrenItem.path] " *ngIf="childrenItem.type === 'link' "
                (click)="toggleNavActive(childrenItem)">
                {{childrenItem.title}}
              </a>
              <!-- empty -->
              <a class="slide-item" routerLinkActive="active" href="javascript:;" *ngIf="childrenItem.type === 'empty' "
                (click)="toggleNavActive(childrenItem)">
                {{childrenItem.title}}
              </a>

              <!-- sub -->
              <a href="javascript:;" class="sub-side-menu__item"
                [routerLink]="childrenItem.type ? null : [childrenItem.path]" *ngIf="childrenItem.type === 'sub' "
                (click)="toggleNavActive(childrenItem)">
                <span class="sub-side-menu__label"> {{childrenItem.title}}</span>
                <i class="sub-angle fe fe-chevron-right"></i>
              </a>

              <!-- 3rd Level menu -->
              <ul class="sub-slide-menu" *ngIf="childrenItem.children" [ngClass]="{'open': childrenItem.active}"
                [ngClass]="{'open': childrenItem.active}">
                <li *ngFor="let childrenSubItem of childrenItem.children"
                  [ngClass]="{'is-expanded': childrenSubItem.active}">
                  <!-- link -->
                  <a class="sub-slide-item" routerLinkActive="active"
                    [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]"
                    *ngIf="childrenSubItem.type === 'link'" (click)="toggleNavActive(childrenSubItem)">
                    {{childrenSubItem.title}}
                  </a>
                  <!-- empty -->
                  <a class="sub-slide-item" href="javascript:;" *ngIf="childrenSubItem.type === 'empty'"
                    (click)="toggleNavActive(childrenSubItem)">
                    {{childrenSubItem.title}}
                  </a>
                  <!-- sub -->
                  <a class="sub-slide-item" href="javascript:;"
                    [routerLink]="childrenSubItem.type ? null : [childrenSubItem.path]"
                    *ngIf="childrenSubItem.type === 'sub' " (click)="toggleNavActive(childrenSubItem)">
                    <span class="sub-side-menu__label2"> {{childrenSubItem.title}} </span>
                    <i class="sub-angle2 fe fe-chevron-right"></i>
                  </a>

                  <!--4th level -->
                  <ul class="sub-slide-menu2" *ngIf="childrenSubItem.children"
                    [ngClass]="{'open': childrenSubItem.active}">
                    <li class=" " *ngFor="let childrenSubItem1 of childrenSubItem.children"
                      [ngClass]="{'is-expanded': childrenSubItem1.active}">
                      <a class="sub-slide-item2" routerLinkActive="active"
                        [routerLink]="!childrenSubItem1.type ? null : [childrenSubItem1.path]"
                        *ngIf="childrenSubItem1.type === 'link'">
                        {{childrenSubItem1.title}}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <!-- right-arrow  -->
      <div class="slide-right " id="slide-right"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24"
          height="24" viewBox="0 0 24 24">
          <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
        </svg></div>
      <!-- <div class="slide-rightRTL" id="slide-rightRTL"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24"
        height="24" viewBox="0 0 24 24">
        <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" /></svg></div> -->
    </div>
  </ng-scrollbar>
</div>