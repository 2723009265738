import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Validators } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';
import { UserBackofficeResetPasswordRequestDto } from 'src/app/dtos/authentication/user-backoffice-reset-password-request.dto';
import { UserBackofficeResetPasswordConfirmationRequestDto } from 'src/app/dtos/authentication/user-backoffice-reset-password-request.dto copy';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  

  form: FormGroup;

  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;

  resendCode: number = 50;

  code: number | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private toastrService: ToastrService
  ) {
    this.form = this.formBuilder.group({
      email: [''],
      code: [''],
      password: ['', [Validators.minLength(6), Validators.maxLength(12)]],
      passwordConfirm: [''],
    })
  }

  ngOnInit(): void {
    
  }

  countDown() {
    var count = setInterval(() => {
      if (this.resendCode > 0) {
        this.resendCode -= 1
      }
      if (this.resendCode === 0) {
        clearInterval(count)
      }
    }, 1000);
  }

  sendEmail() {

    const dto: UserBackofficeResetPasswordRequestDto = {
      email: this.form.controls['email'].value
    }
    this.userService.resetPassword(dto).subscribe({
      next: data => {
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.countDown();

        this.toastrService.success('Código enviado com sucesso!', '', { progressBar: true })

      },
      error: err => {
        console.error(err);

        if (err.error.errors[0] == 'An email has already been sent and is valid!') {
          this.toastrService.warning('Um código já foi enviado e é válido!', '', { progressBar: true });
          this.step1 = false;
          this.step2 = true;
          this.step3 = false;
        } else if (err.error.errors[0] == 'Email not found') {
          this.toastrService.error('Email não encontrado!', '', { progressBar: true });
        } else {
          this.toastrService.error('Erro ao enviar código!', '', { progressBar: true });
        }

       
      }
    });
  }

  backSendEmail() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
  }

  sendCode() {
    this.step1 = false
    this.step2 = false;
    this.step3 = true;
  }

  backSendCode() {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
  }

  backLogin() {
    this.router.navigate(['/'])
  }

  forgotPassword() {

    if(this.form.controls['password'].value !== this.form.controls['passwordConfirm'].value) {
      this.toastrService.error('As senhas devem ser iguais!', '', { progressBar: true });
      return;
    } else if(this.form.controls['password'].value.length < 6 || this.form.controls['password'].value.length > 12) {
      this.toastrService.error('Senha deve conter no mínimo 6 caracteres e no máximo 12!', '', { progressBar: true });
      return;
    } else if(this.form.controls['email'].value == '') {
      this.toastrService.error('E-mail é obrigatório!', '', { progressBar: true });
      this.backSendEmail();
      return;
    } else if(this.form.controls['code'].value == '') {
      this.toastrService.error('Código é obrigatório!', '', { progressBar: true });
      this.backSendCode();
      return;
    }

    const dto: UserBackofficeResetPasswordConfirmationRequestDto = {
      email: this.form.controls['email'].value,
      code: this.form.controls['code'].value,
      password: this.form.controls['password'].value
    }

    
    this.userService.resetPasswordConfirmation(dto).subscribe({
      next: data => {

        this.toastrService.success('Senha alterada com sucesso!', '', { progressBar: true })
        
        this.backLogin();
      },
      error: err => {
        console.error(err);

        if(err.error.errors[0] == 'Código inválido!'){
          this.toastrService.error('Código inválido!', '', { progressBar: true });
          this.backSendCode();
        } else {
          this.toastrService.error('Erro ao alterar senha!', '', { progressBar: true });
        }

      
      }
    });

  }
}
