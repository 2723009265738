import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { Observable } from 'rxjs';
import { UserService } from "src/app/services/user.service";
import { SellerInterface } from "../../../dtos/seller/response-seller.dto";
import { SellerService } from "../../../services/seller.service";
import { ShopService } from "../../services/e-commerce/shop.service";
import { LayoutService } from "../../services/layout.service";
import { Menu, NavService } from "../../services/nav.service";
import { SwitcherService } from "../../services/switcher.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [SellerService],
})
export class HeaderComponent implements OnInit {
  public isCollapsed = true;
  userLocal = JSON.parse(localStorage.getItem("user") || "{}");

  data$: Observable<any>;
  totalMoney: number = 0;
  totalLength: number = 1;
  delectFunction: boolean = false;
  getdelectData: any;
  user: any;
  seller?: SellerInterface;

  constructor(
    private layoutService: LayoutService,
    public navServices: NavService,
    private modalService: NgbModal,
    public SwitcherService: SwitcherService,
    private router: Router,
    public ShopService: ShopService,
    private store: Store<any>,
    private userService: UserService,
    private sellerSerivce: SellerService
  ) {
    this.data$ = this.store.select("data");
  }

  ngOnInit(): void {
    this.initializeComponent();
  }

  private initializeComponent(): void {
    this.setupDataSubscription();
    this.setupNavItems();
    this.setupSearchFieldClear();
    this.initializeUser();
  }

  private setupDataSubscription(): void {
    this.data$.subscribe(item => {
      this.calculateTotalMoney(item);
    });
  }

  private calculateTotalMoney(item: any[]): void {
    this.totalLength = item.length;
    if (this.delectFunction) {
      this.totalMoney = 0;
      this.delectFunction = false;
      return;
    }
    if (this.totalLength > 1) {
      this.totalMoney += item[this.totalLength - 1]?.offer_price || 0;
    } else if (this.totalLength === 1) {
      this.totalMoney = item[0]?.offer_price || 0;
    }
  }

  private setupNavItems(): void {
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems;
    });
  }

  private setupSearchFieldClear(): void {
    document.querySelector(".main-content")?.addEventListener("click", () => {
      this.clearSearch();
    });
  }

  private initializeUser(): void {
    if (this.userLocal?.type === "seller") {
      this.getSeller();
    } else {
      this.getUser();
    }
  }

  getUser(): void {
    this.userService.getUser().subscribe({
      next: data => {
        this.user = data;
      },
      error: error => {
        console.error(error);
      },
    });
  }

  getSeller(): void {
    this.sellerSerivce.getSellerAuth().subscribe({
      next: data => (this.seller = data),
      error: err => console.error(err),
    });
  }

  toggleSidebar() {
    if ((this.navServices.collapseSidebar = true)) {
      document.querySelector("body")?.classList.toggle("sidenav-toggled");
    }
  }

  open(content: any) {
    this.modalService.open(content, { backdrop: "static", windowClass: "modalCusSty", size: "lg" });
  }

  toggleSwitcher() {
    this.SwitcherService.emitChange(true);
    document.querySelector("body")?.classList.remove("sidenav-toggled-open");
  }

  toggleSidebarNotification() {
    this.layoutService.emitSidebarNotifyChange(true);
  }

  signout() {
    // this.auth.SignOut();
    this.router.navigate(["/auth/login"]);
  }

  // Search
  public menuItems!: Menu[];
  public items!: Menu[];
  public text!: string;
  public SearchResultEmpty: boolean = false;

  Search(searchText: any) {
    if (!searchText) return (this.menuItems = []);
    // items array which stores the elements
    let items: any[] = [];
    // Converting the text to lower case by using toLowerCase() and trim() used to remove the spaces from starting and ending
    searchText = searchText.toLowerCase().trim();
    this.items.filter((menuItems: any) => {
      // checking whether menuItems having title property, if there was no title property it will return
      if (!menuItems?.title) return false;
      //  checking wheteher menuitems type is text or string and checking the titles of menuitems
      if (menuItems.type === "link" && menuItems.title.toLowerCase().includes(searchText)) {
        // Converting the menuitems title to lowercase and checking whether title is starting with same text of searchText
        if (menuItems.title.toLowerCase().startsWith(searchText)) {
          // If you want to get all the data with matching to letter entered remove this line(condition and leave items.push(menuItems))
          // If both are matching then the code is pushed to items array
          items.push(menuItems);
        }
      }
      //  checking whether the menuItems having children property or not if there was no children the return
      if (!menuItems.children) return false;
      menuItems.children.filter((subItems: any) => {
        if (subItems.type === "link" && subItems.title.toLowerCase().includes(searchText)) {
          if (subItems.title.toLowerCase().startsWith(searchText)) {
            // If you want to get all the data with matching to letter entered remove this line(condition and leave items.push(subItems))
            items.push(subItems);
          }
        }
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems: any) => {
          if (subSubItems.title.toLowerCase().includes(searchText)) {
            if (subSubItems.title.toLowerCase().startsWith(searchText)) {
              // If you want to get all the data with matching to letter entered remove this line(condition and leave items.push(subSubItems))
              items.push(subSubItems);
            }
          }
        });
        return;
      });
      return (this.menuItems = items);
    });
    // Used to show the No search result found box if the length of the items is 0
    if (!items.length) {
      this.SearchResultEmpty = true;
    } else {
      this.SearchResultEmpty = false;
    }
    return;
  }

  //  Used to clear previous search result
  clearSearch() {
    this.text = "";
    this.menuItems = [];
    this.SearchResultEmpty = false;
    return this.text, this.menuItems;
  }
}
